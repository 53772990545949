<template>
	<div class="bg-mafia-dark flex-column position-relative">
		<div class="flex-1 flex-column justify-center">
			<div><img :src="require('@/assets/image/web/intro/ico_char02.svg')" /></div>

			<div class="text_guide pt-20">
				<h3
					v-if="type == 'already'"
				>계정을 확인하세요.</h3>
				<h3
					v-else-if="type == 'out'"
				>탈퇴된 계정입니다.</h3>
				<h3
					v-else-if="type == 'stop'"
				>사용정지된 계정입니다.</h3>
				<h3
					v-else-if="type == 'sleep'"
				>휴면 계정입니다.</h3>
				<h3
					v-else
				>요청 처리 실패</h3>
			</div>
			<div class="text_guide_desc pt-15 color-gray">
				<p
					v-if="type == 'already'"
				>현재 가입된 계정으로 확인됩니다.</p>
				<p
					v-else-if="type == 'out'"
				>{{ msg }}</p>
				<p
					v-else-if="type == 'stop'"
				>{{ msg }}</p>
				<p
					v-else-if="type == 'sleep'"
				>{{ msg }}</p>
				<template
					v-else-if="type == 'third'"
				>
					<p class="error">{{ msg }}</p>
				</template>
			</div>
		</div>


    <div class="intro_btn_wrap mb-20">
      <div class="btn btn_area">
        <button class="btn_l btn_fill_blue" @click="toTry">확인</button>
      </div>
    </div>

	</div>
</template>

<script>
export default {
	name: 'mafia009'
	,data: function(){
		return {
			program: {
				name: '가입 계정 확인'
				, not_header: true
				, not_footer: true
				, type: 'auth'
			}
			, type: this.$route.params.type
		}
	}
	, computed: {
		msg: function(){
			let t = '문제가 지속되면 관리자에게 문의하세요'

			if(this.$route.path.msg){
				t = this.$route.path.msg
			}

			return t
		}
	}
	,methods: {
		toNext: function(){
			if(this.type == 'already'){
				this.$emit('to', { name: 'login'})
			}else if(this.type == 'out'){
				this.$emit('to', { name: 'login'})
			}else {
				this.$emit('to', { name: 'login'})
			}
		}
		, toTry: function(){
			document.location.href = '/'
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
	}
}
</script>
<style>
h3 {
  color : white
}

.intro_btn_wrap { margin: 0 2rem; left: 0; right: 0; bottom: 3rem; }
.btn_area { display: flex; margin: 0 -0.5rem; }
.btn_area button, .btn_area a { margin: 0 0.5rem; padding: 0 1rem; flex: 1; }


.btn { display: inline-block; width: 100%; text-align: center; padding: 10px; font-size: 16px; color: white;}
.btn_l {
  display: inline-block;
  height: 3.5rem;
  line-height: 1rem;
  min-width: 400px;
  padding: 0 3rem;
  border-radius: 5rem;
  border-style: solid;
  border-width: 0.1rem;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 1.1rem;
  text-align: center;
  box-sizing: border-box;
}
</style>